<template>
  <div class="UpgradeTask">
    <div class="filter-pane">
      <label>状态</label>
      <el-select v-model="searchParams.taskStatus"
        size="mini"
        placeholder="请选择">
        <el-option v-for="item in selectList"
          :label="item.label"
          :value="item.value"
          :key="item.label"></el-option>
      </el-select>
      <label>开始时间</label>
      <el-date-picker v-model="searchParams.startTime"
        type="date"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        placeholder="选择日期"
        :picker-options="pickerOptions"></el-date-picker>
      <label>结束时间</label>
      <el-date-picker v-model="searchParams.endTime"
        type="date"
        :picker-options="pickerOptions"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"></el-date-picker>

      <el-button class="search-btn"
        type="primary"
        icon="el-icon-search"
        size="mini"
        @click="getList">查询</el-button>

      <div class="button-pane">
        <el-button type="primary"
          size="mini"
          @click="upgradeClick">
          <Iconfont class="iconClass"
            name="icon-shengji5"></Iconfont>升级
        </el-button>
        <el-button type="danger"
          size="mini"
          @click="editClick"
          icon="el-icon-circle-plus-outline">添加</el-button>
        <el-button type="success"
          @click="deleteClick"
          size="mini"
          icon="el-icon-circle-close">删除</el-button>
      </div>
    </div>

    <div class="table-pane">
      <el-table :data="tableData"
        stripe
        height="94%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection"
          width="55"></el-table-column>
        <el-table-column type="index"
          width="50"
          label="序号"></el-table-column>
        <el-table-column prop="plate"
          label="设备名称"
          width="180"></el-table-column>
        <el-table-column prop="targetFirmwareVersion"
          label="目标固件版本号"
          width="180"></el-table-column>

        <el-table-column prop="taskStatus"
          label="任务状态"
          width="180">
          <template v-slot="{row}">{{selectOptions[row.taskStatus] || '未知'}}</template>
        </el-table-column>
        <el-table-column prop="operUserName"
          label="操作用户名"
          width="180"></el-table-column>
        <el-table-column prop="taskBeginTime"
          label="任务开始时间"
          width="180">
          <template v-slot="{row}">{{ row.taskBeginTime |formatDate}}
          </template>
        </el-table-column>
        <el-table-column prop="taskEndTime"
          label="任务结束时间"
          width="180">
          <template v-slot="{row}">{{ row.taskEndTime |formatDate}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime"
          label="创建时间">
          <template v-slot="{row}">{{ row.createTime |formatDate}}
          </template>
        </el-table-column>
        <el-table-column label="操作"
          align="center">
          <template v-slot="{row}">
            <Iconfont class="editIcone"
              @click="editClick(row)"
              name="icon-bianji1"></Iconfont>
            <!-- <el-button type="text" @click="deleteClick(row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination :total="tablePage.total"
        :current-page="tablePage.pageNumber"
        @current-change="changePage"
        :page-size="tablePage.pageSize"></el-pagination>
    </div>

    <add-modal :visible="addVisible"
      @input="closeDialog"
      :iProp="modalInfo"
      @on-ok="getList"></add-modal>
  </div>
</template>

<script>
import { getTerminalGradeTaskList, delUpgradeTask, execUpgrade } from '@/api/getHome.js';
import addModal from './components/addTaskModal.vue';
import { parseTime } from "@/utils/formatDate";

export default {
  name: "UpgradeTask",
  components: { addModal },
  data() {
    return {
      searchParams: { // 查询参数
        taskStatus: "",
        startTime: "",
        endTime: "",
      },
      selectList: [ // 状态
        { label: '失败', value: -1 },
        { label: '未开始', value: 0 },
        { label: '执行中', value: 1 },
        { label: '完成', value: 2 },
        { label: '超时', value: 3 },
        { label: '取消', value: 4 },
        { label: '全部', value: '' },
      ],
      selectOptions: { // 状态
        '-1': '失败',
        '0': '未开始',
        '1': '执行中',
        '2': '完成',
        '3': '超时',
        '4': '取消',
        // { label: '执行中', value: 1 },
        // { label: '完成', value: 2 },
        // { label: '超时', value: 3 },
        // { label: '取消', value: 4 },
        // { label: '全部', value: '' },
      },
      tablePage: { // 分页参数
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      selectPackages: [], // 批量选择的升级任务

      addVisible: false,
      modalInfo: {},
      tableData: [],
      pickerOptions: {
        disabledDate(time) {
          let date = new Date();
          // date.setFullYear(date.getFullYear()+2);
          // date.setDate(date.getDate()-1);
          return (time.getTime() > date.getTime());
        }
      },
    };
  },
  filters: {
    formatDate(val) {
      return val ? parseTime(val) : '无';
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    /** 获取升级任务list */
    async getList() {
      if (new Date(this.searchParams.startTime) > new Date(this.searchParams.endTime)) {
        this.$message({
          message: '请选择正确的开始和结束时间',
          type: 'warning'
        });
        return false;
      }
      if ((this.searchParams.startTime || this.searchParams.endTime) && (!this.searchParams.startTime || !this.searchParams.endTime)) {
        this.$message({
          message: '请选择完整的时间范围',
          type: 'warning'
        });
        return false;
      }
      let searchParams = JSON.parse(JSON.stringify(this.searchParams));
      if (searchParams.startTime) {
        searchParams.startTime = searchParams.startTime + ' 00:00:00';
        searchParams.endTime = searchParams.endTime + ' 23:59:59';
      }
      try {
        const result = await getTerminalGradeTaskList({
          ...searchParams,
          pageIndex: this.tablePage.pageNumber,
          pageSize: this.tablePage.pageSize,
        });

        if (result.flag !== 1) return this.$message.error(result.msg);

        // this.$message.success(result.msg);
        this.tableData = result.rows;
        this.tablePage.total = result.total;

      } catch ({ msg }) {
        console.log(msg);
        this.$message.error(msg);
      }
    },

    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.getList();
    },
    /** 表格勾选 */
    handleSelectionChange(val) {
      this.selectPackages = val;
    },

    // 模态框关闭
    closeDialog(model) {
      this.addVisible = model;
    },

    // 删除升级任务
    deleteClick() {
      if (!this.selectPackages.length) return this.$message.error('请选择至少一条升级任务');

      let params = {
        ids: this.selectPackages.map(p => p.id).join(',')
      };
      this.$confirm('此操作将永久删除文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const result = await delUpgradeTask(params);

        if (result.flag !== 1) return this.$message.error(result.msg);

        this.getList();
        this.$message.success('删除成功');
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },

    // 执行升级任务
    upgradeClick() {
      if (!this.selectPackages.length) return this.$message.error('请选择至少一条升级任务');

      let params = {
        taskId: this.selectPackages.map(p => p.id).join(',')
      };
      this.$confirm('您将升级选中的任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const result = await execUpgrade(params);

        if (result.flag !== 1) return this.$message.error(result.msg);

        this.getList();
        this.$message.success('下发成功');
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },

    // 编辑点击事件 
    editClick(row) {
      console.log(row);
      const { id } = row;
      this.modalInfo = {};
      if (id) this.modalInfo = row;
      this.addVisible = true;
    },
  },
}
</script>

<style lang="scss" scoped>
.UpgradeTask {
  width: 100%;
  height: 100%;
  position: relative;
}
.filter-pane {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > label {
    margin: 0 8px;
    color: #666666;
    font-size: 12px;
  }
  & > .el-input {
    width: 130px;
  }
}
.search-btn {
  margin: 0 10px;
}
.button-pane {
  position: absolute;
  right: 0;
}
.el-select {
  width: 100px;
}
.table-pane {
  width: 100%;
  height: calc(100% - 60px);
  margin-top: 25px;
  box-shadow: 0px 0px 10px 1px rgba(66, 120, 201, 0.2);
  border-radius: 8px;
}
.el-table {
  border-radius: 8px;
}
.el-pagination {
  text-align: right;
  margin: 5px 0;
}
.iconClass {
  font-size: 14px;
  margin: 0 4px 0 0;
}
.editIcone {
  font-size: 24px;
  color: #4278c9;
}
</style>