<template>
  <Dialog
    :value="visible"
    width="540px"
    height="auto"
    okText="确定"
    :submit="submitOk"
    hiddenCancel
    @close="$emit('input',$event)"
  >
    <!-- 标题 -->
    <template #title>
      <span>编辑升级包</span>
    </template>

    <!-- 内容区-->
    <div class="mainCls">
      <div class="GroupPane" v-if="!editType">
        <label class="required">选择车辆</label>
        <SelectGroupTree
          style="margin:-2px;width:80%"
          placeholder="选择上级车组"
          :multipleProp="{
            vehicleCheckBox:true,
            noGroup:true
          }"
          @on-select="onSelect"
          @on-check="Confirm"
          @clear="clearClick"
          :selecteds="selecteds"
        />
      </div>

      <el-input disabled placeholder="请输入选择车辆" v-model="plate" v-else>
        <template slot="prepend">选择车辆</template>
      </el-input>

      <div class="GroupPane" style="margin-top:10px">
        <label class="required">选择升级包</label>
        <el-select
          v-model="formParams.terminalUpgradeId"
          placeholder="请选择"
          @change="changePackage"
          style="width:80%"
        >
          <el-option
            v-for="item in packageOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <el-input
        :disabled="editType"
        placeholder="请输入制造商"
        v-model="formParams.manufacturers"
      >
        <template slot="prepend">制造商</template>
      </el-input>
    </div>
  </Dialog>
</template>

<script>

/**
 * 报警处理
 */

import Dialog from '@/components/Dialog/DialogBright';
import { mapState } from 'vuex';
import { getTerminalUpgrade, addUpgradeTask, updateTask } from '@/api/getHome.js';
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';

export default {
  name: 'AlarmHandelDialog',
  components: {
    Dialog,
    SelectGroupTree
  },
  props: {
    /**
     * 控制弹框
     */
    visible: {
      type: Boolean,
      default: false,
    },
    /**
     * 传承参数
     */
    iProp: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      formParams: this.initParams(),
      fileName: '', // 显示的文件name
      editType: false,
      selecteds: [],

      packageOptions: [],//获取升级包
      plate: ''
    };
  },
  mounted() {
    this.getPackageList(); // 获取升级包
  },
  watch: {
    visible: {
      handler(val) {
        this.editType = false;
        if (!val) return this.formParams = this.initParams();



        console.log(this.packageOptions);
        const { id, plate } = this.iProp;
        if (!id) return;

        this.plate = plate;
        this.formParams = this.iProp;
        this.editType = true;
      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  methods: {
    // 初始化数据
    initParams() {
      return {
        vehicleIds: [],
        targetFirmwareVersion: '', // 目标固件版本号（升级包版本号）
        terminalUpgradeId: '',  // 固件包id（升级包Id）
        manufacturers: '', // 制造商
      };
    },

    /** 获取升级包list */
    async getPackageList() {
      try {
        const result = await getTerminalUpgrade({
          ...this.searchParams,
        });

        if (result.flag !== 1) return this.$message.error(result.msg);

        this.packageOptions = result.rows.map(item => ({
          id: item.id,
          label: item.packName,
          version: item.packHardwareVersion
        }));

      } catch ({ msg }) {
        console.log(msg);
        this.$message.error(msg);
      }
    },

    // 升级包选择事件
    changePackage(val) {
      if (!val) return;

      const data = this.packageOptions.filter(item => item.id === val)[0];
      this.formParams.targetFirmwareVersion = data.version;
    },

    //清除输入框文件
    clearclick() {
      this.fileName = '';
      this.formParams.packFile = null;
    },
    // 关闭模态框
    close() {
      this.$emit('input', false);
    },

    // 清除车辆
    clearClick() {
      this.formParams.vehicleIds = [];
    },

    //车组车辆搜索框选择
    onSelect(node) {
      const { vehicleId } = node;
      this.formParams.vehicleIds = [...this.formParams.vehicleIds, vehicleId];
    },

    // 选择车组
    Confirm(node) {
      this.formParams.vehicleIds = node.map(item => item.vehicleId);
    },

    //数据校验
    validateData() {
      const { vehicleIds, terminalUpgradeId } = this.formParams;
      if (!vehicleIds.length && !vehicleIds) throw '请选择至少一辆车';
      if (!terminalUpgradeId) throw '请选择升级包';
    },

    // 点击确定添加
    async submitOk() {
      try {
        const { id } = this.iProp;
        let params = this.formParams;

        if (!id) this.validateData();

        if (id) params = {
          id: this.formParams.id,
          packId: this.formParams.terminalUpgradeId,
          packVersion: this.formParams.targetFirmwareVersion
        };
        
        const url = id ? updateTask : addUpgradeTask;

        const result = await url(params);

        if (result.flag !== 1) return this.$message.error(result.msg);

        this.$message.success(result.msg);
        this.$emit('on-ok');
        this.close();
      } catch (error) {
        console.log(error);
        this.$message.error(error);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.mainCls {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  .el-input {
    margin: 8px 0;
  }
}
.GroupPane {
  display: flex;
  align-items: center;
  & > label {
    display: block;
    width: 105px;
    line-height: 31px;
    text-align: center;
    border: 1px solid #dcdfe6;
    background: #f5f7fa;
    color: #909399;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-right: none;
  }
}
::v-deep .el-upload {
  width: 100% !important;
}
.required:before {
  content: '* ';
  color: red;
}

::v-deep input.el-input__inner {
  border-radius: 0px;
}

::v-deep .el-select .el-input__inner {
  height: 33px;
}
</style>