<template>
  <div v-if="visible" class="dialog-wrapper">
    <el-dialog
      :visible="visible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :width="width"
      custom-class="custom_outer"
      :destroy-on-close="destroyClose"
      :append-to-body="appendToBody"
    >
      <!-- 标题 -->
      <template v-if="showHeader" #title>
        <div class="header">
          <slot v-if="$slots.title" name="title"></slot>
          <span v-else>{{title}}</span>
          <!-- 关闭 -->
          <span class="exit-icon" title="关闭" @click="onClose">
            <ElIcon name="el-icon-close"></ElIcon>
          </span>
        </div>
      </template>

      <!-- 内容区 -->
      <div v-if="visible" :style="mainStyle" :class="['modal-main', mainClass]">
        <slot></slot>
      </div>

      <!-- 底部 -->
      <template v-if="showFooter" #footer>
        <slot v-if="$slots.footer" name="footer"></slot>
        <span v-else slot="footer" class="bottom">
          <el-button
            v-if="!hiddenCancel"
            @click="onClose"
          >{{cancelText}}</el-button>
          <el-button
            :loading="btnLoading"
            type="primary"
            @click="onSure"
          >{{okText}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

/**
 * 基于el-dialog封装模态框
 * 统一样式
 */


import { mapState } from 'vuex';
import { isNumber, isFunction } from 'lodash';

export default {
  name: 'DialogTpl',
  components: {
  },
  emits: [
    // 关闭事件
    'close',
    // 确定事件
    'ok'
  ],
  props: {
    /**
     * 控制隐藏显示
     */
    value: {
      type: Boolean,
      default: false
    },
    /**
     * 标题
     */
    title: {
      type: String,
      default: '标题'
    },
    // 确定显示文本
    okText: {
      type: String,
      default: '确 定'
    },
    // 取消显示文本
    cancelText: {
      type: String,
      default: '取 消'
    },
    // 隐藏取消button
    hiddenCancel: {
      type: Boolean,
      default: false,
    },
    /**
     * 宽度
     */
    width: {
      type: String,
      default: 'calc(100vw - 400px)',
    },
    /**
    * 内容区高度
    */
    height: {
      type: String,
      default: 'calc(100vh - 265px)'
    },
    /**
     * 显示头部
     */
    showHeader: {
      type: Boolean,
      default: true
    },
    /**
     * 显示底部
     */
    showFooter: {
      type: Boolean,
      default: true,
    },
    /**
     * 点击确认时, 是否关闭
     */
    closeOnOk: {
      type: Boolean,
      default: false,
    },

    /**
     * 内容区样式
     */
    mainClass: {
      type: String,
      default: '',
    },
    /**
     * 提交回调函数
     * @return {Boolean}  true: 立即关闭; 其他: 不关闭
     */
    submit: {
      type: [Function, Promise],
      default: null,
    },

    /**
     * 关闭时销毁 Dialog 中的元素
     */
    destroyClose: {
      type: Boolean,
      default: true
    },

    /**
     * Dialog 自身是否插入至 body 元素上
     */
    appendToBody: {
      type: Boolean,
      default: true
    }

  },
  data() {
    return {
      // 是否显示
      visible: false,
      // 提交状态
      btnLoading: false,
    };
  },
  computed: {
    /**
     * 内容区样式
     */
    mainStyle() {
      const { height } = this;
      const styleObj = {};

      styleObj.height = height;

      return styleObj;
    }
  },
  watch: {
    value(val) {
      this.visible = val;
      if (val === false) {
        this.btnLoading = false;
      }
    },
  },
  beforeMount() {
    this.visible = this.value;
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    /**
     * 打开
     */
    open() {
      this.visible = true;
      this.$emit('input', true);
    },
    /**
     * 关闭
     */
    close() {
      this.visible = false;
      this.btnLoading = false;
      this.$emit('close');
      this.$emit('input', false);
    },
    /**
     * 确定
     */
    async onSure() {
      const { submit, closeOnOk } = this;
      this.btnLoading = true;

      try {
        if (submit) {
          // 提交`submit`返回true, 则立即关闭
          const resultOk = (await submit());
          if (resultOk === true) {
            this.close();
          }
        } else if (closeOnOk) {
          this.close();
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.btnLoading = false;
      }
    },
    // 关闭事件
    async onClose() {
      this.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  position: relative;
  height: 0;
}
::v-deep.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-dialog.custom_outer {
    margin: initial !important;
    border-radius: 8px !important;

    .el-dialog__headerbtn {
      top: 12px;
    }
    .el-dialog__header {
      padding: 10px;
      border-bottom: 1px solid #e8eaec;
      font-weight: 400;
      background: #4278c9;
      font-size: 13px;
      color: #ffffff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .el-dialog__footer {
      top: initial;
      padding: 10px 20px;
      text-align: center !important;
      border-top: 1px solid #e8eaec;
    }

    .el-dialog__body {
      padding: initial;
    }
  }
}

.exit-icon {
  position: absolute;
  right: 0px;
  top: -2px;
  cursor: pointer;
  width: 20px;
  text-align: center;
}

.header {
  position: relative;
}

.modal-main {
  font-size: 14px;
  position: relative;
  display: flex;
}
.bottom {
  position: relative;
  height: 35px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  & > button {
    margin: 0 3px;
  }
}
</style>

